<template>
<div class="page login">
  <img class="logo" src="logo.png" alt="" />
    <div class="tips">未找到商家</div>
</div>
</template>

<style lang='scss' scord>
</style>

<script>
export default {
data () {
return {

}
},

created () {

},

methods: {

}
}
</script>